#custom_header {
	background-color: white;
	padding: 10px 20px;
	position: fixed;
	width: 100%;
	height: 80px;
	z-index: 999;
	/* top: -10px; */
	/* box-shadow: 0px 5px 11px 0px rgb(196 196 196 / 50%); */
}
.headerNav {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.headerNav h4 {
	color: black;
}
.headerNav .logo {
	display: inline-block;
	/* margin-left: auto;
	margin-right: auto; */
	flex: 0 0 20%;
}
.headerNav .logo-header {
	margin-bottom: 0;
}
.headerNav ul {
	margin-bottom: 0;
}
@media screen and (max-width: 767px) {
	#custom_header {
		display: none;
	}
}
@media screen and (max-width: 1023px) {
	#custom_header {
		display: none;
	}
}
