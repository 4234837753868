html,
body {
	overflow: auto !important;
}
.head .container {
	max-width: 1200px;
}
#price {
	padding-top: 130px !important;
	padding: 50px 0;
	color: black;
	height: 100vh;
}
.pricing {
	color: black;
	height: auto;
}
.price-item {
	padding: 50px 30px;
	text-align: left;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-wrap: wrap;
	/* height: 480px; */
}
.price-item h5 {
	font-size: 1.1rem;
}

.price-item .price-list {
	margin: 0;
	padding: 0;
}

.price-item .description {
	font-size: 14px;
}

.price-item li {
	margin-bottom: 20px;
	display: block;
	font-size: 16px;
}

.price-item .period {
	font-size: 18px;
}

.price-item .price-list li i {
	text-align: center;
	border-radius: 50px;
	margin-right: 12px;
	padding: 4px;
	color: #fff;
	font-size: 8px;
}

.price-item .price-list.blue li i {
	background-color: #55a8fd;
}

.price-item .price-list.green li i {
	background-color: #00d58c;
}
.sliderBar {
	height: 10px !important;
	border-radius: 10px !important;
	background-color: #f2f2f5;
	cursor: pointer;
	-webkit-transition: background-color 0.3s ease-in-out;
	transition: background-color 0.3s ease-in-out;
}
.rs-slider {
	position: relative;
}
.rs-slider-progress-bar {
	background-color: #3498ff;
	height: 10px !important;
	border-radius: 6px 0 0 6px !important;
	position: absolute;
}
.price-item-left,
.price-item-right {
	flex: 0 0 50%;
	padding: 10px;
}
.price-item-right {
	padding-left: 15px;
}
.rs-slider-handle {
	outline: none;
	position: absolute;
	top: -12px !important;
	background-color: #3498ff;
	text-align: center;
	padding: 7px;
	margin-left: -16px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

	&:hover {
		box-shadow: 0 0 0 8px rgb(52 152 255 / 25%);
	}

	&::before {
		display: none;
	}
}
#p-footer {
	background-color: #041f60;
	padding-top: 90px !important;
	padding: 40px 0;
	background-image: url("../../images/contact-bg.jpg");
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
}
/* #p-footer .box-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	background-color: rgba(255, 255, 255, 0.2);
}
#p-footer .box {
	padding: 30px;
}
#p-footer .box h2 {
	font-size: 30px;
	font-weight: bold;
} */
/* #p-footer h2 {
	font-size: 48px;
	font-weight: bold;
} */
.btn.btn-green {
	background: #00d58c;
	border-color: #00d58c;
	color: #ffffff;
}
.btn-outline-primary {
	border-color: #007bff !important;
}
@media screen and (max-width: 767px) {
	.price-item-left,
	.price-item-right {
		flex: 0 0 100%;
	}
	#price {
		height: auto;
		/* padding-top: 40px !important; */
	}
	#p-footer {
		height: auto;
	}
}

iframe {
	width: 80% !important;
}
